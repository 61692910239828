import React, { useContext } from 'react'
import styles from './LineItem.module.scss'
import getPrice from '~/components/00-global/util/price'
import { getSizedImageUrl } from '@shopify/theme-images'
import { Link, useIntl } from 'gatsby-plugin-intl'

import StoreContext from '~/context/StoreContext'

const LineItem = ({ item }) => {
  const intl = useIntl()
  const {
    updateLineItem,
    store: { client, checkout }
  } = useContext(StoreContext)

  const increaseQuantity = () =>
    updateLineItem(client, checkout.id, item.id, item.quantity + 1)

  const decreaseQuantity = () =>
    updateLineItem(client, checkout.id, item.id, item.quantity - 1)

  return (
    <div className={styles.lineItem}>
      <div className={styles.itemContainer}>
        {item.variant.image && (
          <Link
            className={styles.imageContainer}
            to={`/produkt/${item.variant.product.handle}`}
            tabIndex='-1'
          >
            <img
              src={getSizedImageUrl(item.variant.image.src, 'medium')}
              alt={`${item.title} ${item.variant.title} Produkt Bild`}
            />
          </Link>
        )}
        <div className={styles.textQuantityContainer}>
          <Link
            className={styles.textContainer}
            to={`/produkt/${item.variant.product.handle}`}
          >
            <p className={styles.title}>{item.title}</p>

            {item.variant.selectedOptions &&
              item.variant.selectedOptions.map(option => {
                if (
                  option.name !== 'Title' &&
                  option.value !== 'Default Title'
                ) {
                  return (
                    <p
                      className={styles.variant}
                      key={option.name}
                    >{`${option.name}: ${option.value}`}</p>
                  )
                } else {
                  return null
                }
              })}
          </Link>
          <div className={styles.quantityContainer}>
            <button
              className={styles.quantity}
              aria-label={intl.formatMessage({ id: 'cart.increaseQuantity' })}
              onClick={decreaseQuantity}
            >
              －
            </button>
            <div
              className={`${styles.quantity} ${styles.indicator}`}
              aria-label={
                intl.formatMessage({ id: 'cart.selectedQuantity' }) +
                item.quantity
              }
            >
              {item.quantity}
            </div>
            <button
              className={styles.quantity}
              aria-label={intl.formatMessage({ id: 'cart.decreaseQuantity' })}
              onClick={increaseQuantity}
            >
              ＋
            </button>
          </div>
        </div>
      </div>
      <span className={styles.price}>
        {getPrice(item.variant.price.amount * item.quantity)}
      </span>
    </div>
  )
}

export default LineItem
