import React from 'react'
import { changeLocale, useIntl } from 'gatsby-plugin-intl'
import styles from './LocaleSwitcher.module.scss'

const LocaleSwitcher = () => {
  const { locale } = useIntl()
  return (
    <div className={styles.localeSwitcherContainer}>
      <button
        onClick={() => changeLocale('en')}
        className={
          locale === 'en'
            ? `${styles.localeSwitcher} ${styles.active}`
            : styles.localeSwitcher
        }
      >
        🇬🇧
      </button>
      <button
        onClick={() => changeLocale('de')}
        className={
          locale === 'de'
            ? `${styles.localeSwitcher} ${styles.active}`
            : styles.localeSwitcher
        }
      >
        🇩🇪
      </button>
    </div>
  )
}

export default LocaleSwitcher
