import React from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import styles from './Button.module.scss'

const Button = ({
  children,
  variant = 'primary',
  asElement,
  to,
  onClick,
  disabled,
  fullwidth
}) => {
  return (
    <BootstrapButton
      className={`${styles.button} ${styles[variant]} ${fullwidth &&
        styles.fullwidth}`}
      as={asElement}
      to={to}
      onClick={onClick}
      disabled={disabled}
    >
      {/* this is the child of button */}
      {children}
    </BootstrapButton>
  )
}

export default Button
