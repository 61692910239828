import React, { useContext, useState, useEffect } from 'react'
import reduce from 'lodash/reduce'
import StoreContext from '~/context/StoreContext'
import Lottie from 'lottie-react-web'
import { Nav, Navbar, Container } from 'react-bootstrap'
import Brand from '~/images/image-logo.svg'
import styles from './Navbar.module.scss'
import Button from '~/components/00-global/Button/Button'
import CartIcon from '~/images/cart.svg'
import Toggle from '~/json/navbar-toggle.json'
import Cart from '~/components/00-global/Cart/Cart'
import { FormattedMessage, useIntl, Link } from 'gatsby-plugin-intl'
import LocaleSwitcher from './LocaleSwitcher'

const useQuantity = () => {
  const {
    store: { checkout }
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const Navigation = () => {
  const intl = useIntl()
  const [hasItems, quantity] = useQuantity()
  const [navbarToggled, setNavbarToggled] = useState(false)
  const [cartToggled, setCartToggled] = useState(false)

  const cartRef = React.createRef()
  const useOutsideClick = ref => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setCartToggled(false)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  useOutsideClick(cartRef)

  return (
    <div className={styles.navbarContainer}>
      <Navbar
        className={styles.navbar}
        expand='md'
        collapseOnSelect
        expanded={navbarToggled}
        onToggle={() => {
          setCartToggled(false)
          setNavbarToggled(!navbarToggled)
        }}
      >
        <Container fluid='xxl'>
          <Navbar.Brand
            className={styles.brand}
            as={Link}
            aria-label='Home'
            to='/'
            onClick={() => setNavbarToggled(false)}
          >
            <img src={Brand} alt='Logo' />
          </Navbar.Brand>

          <div className={styles.container}>
            <div className={styles.shopButton}>
              <Nav.Link
                as={Button}
                asElement={Link}
                to='/shop'
                variant='navbar'
                onClick={() => setNavbarToggled(false)}
              >
                <FormattedMessage id='navbar.toShop' />
              </Nav.Link>
            </div>

            <div className={styles.cart} ref={cartRef}>
              <Nav.Link
                className={styles.button}
                aria-label={
                  cartToggled
                    ? intl.formatMessage({ id: 'navbar.untoggleCart' })
                    : intl.formatMessage({ id: 'navbar.toggleCart' })
                }
                as='button'
                onClick={() => {
                  setNavbarToggled(false)
                  setCartToggled(!cartToggled)
                }}
              >
                {hasItems && <div className={styles.quantity}>{quantity}</div>}
                <img
                  src={CartIcon}
                  alt={intl.formatMessage({ id: 'navbar.cart' })}
                />
              </Nav.Link>

              <Cart
                toggled={cartToggled}
                handleToggle={toggle => setCartToggled(toggle)}
              />
            </div>

            <Navbar.Toggle
              className={styles.toggle}
              label={
                navbarToggled
                  ? intl.formatMessage({ id: 'navbar.untoggleMenu' })
                  : intl.formatMessage({ id: 'navbar.toggleMenu' })
              }
            >
              <Lottie
                tabIndex='-1'
                isPaused={false}
                direction={navbarToggled ? 1 : -1}
                speed={3}
                options={{
                  animationData: Toggle,
                  loop: false
                }}
              />
            </Navbar.Toggle>
          </div>

          <Navbar.Collapse className={styles.collapse}>
            <Nav className={styles.links}>
              <Nav.Link
                className={styles.link}
                as={Link}
                to='/'
                onClick={() => setNavbarToggled(false)}
                activeStyle={{ opacity: 1 }}
              >
                Home
              </Nav.Link>
              <Nav.Link
                className={`${styles.link} ${styles.shop}`}
                as={Link}
                to='/shop'
                onClick={() => setNavbarToggled(false)}
                activeStyle={{ opacity: 1 }}
              >
                Shop
              </Nav.Link>

              <Nav.Link
                className={styles.link}
                as={Link}
                to='/infos'
                onClick={() => setNavbarToggled(false)}
                activeStyle={{ opacity: 1 }}
              >
                Infos
              </Nav.Link>
              <Nav.Link
                className={styles.link}
                as={Link}
                to='/ueber-uns'
                onClick={() => setNavbarToggled(false)}
                activeStyle={{ opacity: 1 }}
              >
                <FormattedMessage id='navbar.aboutUs' />
              </Nav.Link>
              <div className={styles.localeContainer}>
                <LocaleSwitcher />
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Navigation
