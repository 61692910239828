import React from 'react'
import './styles.scss'
import 'focus-visible/dist/focus-visible.js'
import ContextProvider from '~/provider/ContextProvider'
import Navbar from '~/components/00-global/Navbar/Navbar.js'
import Footer from './footer'
import 'animate.css/animate.min.css'
const Layout = ({ children }) => {
  return (
    <ContextProvider>
      <div>
        <Navbar />

        <main>{children}</main>
        <Footer />
      </div>
    </ContextProvider>
  )
}

export default Layout
