//Import React
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Link,
  useIntl,
  FormattedHTMLMessage,
  FormattedMessage
} from 'gatsby-plugin-intl'

//Import Assets
import ImageBrand from '~/images/image-logo.svg'
import ImageBeerLogo from '~/images/image-bier-bewusst-genießen.svg'
import { FaInstagram, FaFacebook } from 'react-icons/fa'

//Import Styles
import './footer.scss'

const Footer = () => {
  const { locale } = useIntl()

  const { legalDE, legalEN } = useStaticQuery(graphql`
    query {
      legalDE: allContentfulLegalPage(filter: { node_locale: { eq: "de" } }) {
        nodes {
          id
          title
          slug
        }
      }
      legalEN: allContentfulLegalPage(filter: { node_locale: { eq: "en" } }) {
        nodes {
          id
          title
          slug
        }
      }
    }
  `)
  return (
    <>
      <div className='svg-correct d-block d-md-none'>
        <svg>
          <clipPath
            id='clip-path-footer-mobile'
            clipPathUnits='objectBoundingBox'
          >
            <path d='M0,0.008 L0.05,0.013 L0.08,0.015 L0.121,0.015 H0.14 L0.191,0.016 H0.208 L0.218,0.015 L0.245,0.015 L0.266,0.016 L0.298,0.019 L0.333,0.023 L0.378,0.027 L0.402,0.03 L0.456,0.033 H0.547 L0.604,0.03 L0.684,0.023 L0.752,0.021 L0.808,0.016 L0.849,0.01 L0.911,0.007 L0.942,0.005 L0.974,0.003 L1,0 V1 H0 V0.008'></path>
          </clipPath>
        </svg>
      </div>
      <div className='svg-correct d-none d-md-block'>
        <svg>
          <clipPath id='clip-path-footer' clipPathUnits='objectBoundingBox'>
            <path d='M0,0.045 L0.05,0.068 L0.08,0.077 L0.121,0.077 H0.14 L0.191,0.086 H0.208 L0.218,0.08 L0.245,0.079 L0.266,0.086 L0.298,0.101 L0.333,0.123 L0.378,0.141 L0.402,0.159 L0.456,0.177 H0.547 L0.604,0.157 L0.684,0.121 L0.752,0.112 L0.808,0.086 L0.849,0.054 L0.911,0.036 L0.942,0.025 L0.974,0.015 L1,0 V1 H0 V0.045'></path>
          </clipPath>
        </svg>
      </div>
      <footer id='footer' className='clipped'>
        <Container>
          <Row>
            <Col md={4} lg={3}>
              <ul>
                <li>
                  <h2>Biersafe</h2>
                </li>
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <Link to='/shop'>Shop</Link>
                </li>
                <li>
                  <Link to='/infos'>Infos</Link>
                </li>
                <li>
                  <Link to='/ueber-uns'>
                    <FormattedMessage id='navbar.aboutUs' />
                  </Link>
                </li>
              </ul>
              <hr />
            </Col>

            <Col md={4} lg={3}>
              <ul>
                <li>
                  <h2>
                    <FormattedMessage id='footer.legal.title' />
                  </h2>
                </li>
                {locale === 'de'
                  ? legalDE.nodes.map(node => (
                      <li key={node.id}>
                        <Link to={`/${node.slug}`}>{node.title}</Link>
                      </li>
                    ))
                  : legalEN.nodes.map(node => (
                      <li key={node.id}>
                        <Link to={`/${node.slug}`}>{node.title}</Link>
                      </li>
                    ))}
                <li>
                  <button
                    onClick={() =>
                      typeof window !== 'undefined' &&
                      window.Osano.cm.showDrawer(
                        'osano-cm-dom-info-dialog-open'
                      )
                    }
                  >
                    <FormattedMessage id='footer.legal.cookieSettings' />
                  </button>
                </li>
              </ul>
              <hr />
            </Col>
            <Col md={4} lg={3} className='ul-last'>
              <ul>
                <li>
                  <h2>
                    <FormattedMessage id='footer.contact.title' />
                  </h2>
                </li>
                <li>
                  <a href='mailto:info@biersafe.de'>info@biersafe.de</a>
                </li>
                <li>
                  <a href='tel:+4928729535595'>02872 95 35 595</a>
                </li>
                <li>
                  <div className='icons'>
                    <div className='icon'>
                      <a
                        href='https://www.instagram.com/biersafe/'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Instagram'
                      >
                        <FaInstagram />
                      </a>
                    </div>
                    <div className='icon'>
                      <a
                        href='https://www.facebook.com/biersafe/'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Facebook'
                      >
                        <FaFacebook />
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <h2>
                    <FormattedMessage id='footer.openingHours.title' />
                  </h2>
                </li>
                <li>
                  <FormattedHTMLMessage id='footer.openingHours.text' />
                </li>
              </ul>
            </Col>
            <Col md={12} lg={3} className='col-last'>
              <div className='top'>
                <img
                  className='brand d-md-none d-lg-block'
                  src={ImageBrand}
                  alt=''
                />
              </div>
              <div className='bottom'>
                <div className='beerlogo'>
                  <img src={ImageBeerLogo} alt='' />
                </div>
                <img
                  className='brand d-none d-md-block d-lg-none'
                  src={ImageBrand}
                  alt=''
                />
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer
